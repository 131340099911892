
/* eslint-disable default-param-last */

import { DASHBOARD_TYPES } from "../redux-constant-type";

interface state {
    orders_transactions: {
        order_year: number,
        order_month: number,
        total_price: number,
        total_quantity: number,
        order_date: Date,
        
    }[],
    current_value_of_tokens_purchased: number,
    total_money_invested: number,
    banner_data: { image_url: string, text: string }[],
    total_tokens_purchased:number,
    current_token_value:  number,
}

const initialState: state = {
    orders_transactions: [],
    total_money_invested: 0,
    current_value_of_tokens_purchased: 0,
    total_tokens_purchased: 0,
    banner_data: [],
    current_token_value: 0
};

const dashboardReducer = (state = initialState, action) => {

    switch (action.type) {
        case DASHBOARD_TYPES.SET_DASHBOARD_DATA:
            return {
                ...state,
                orders_transactions: action.payload.orders_transactions,
                total_money_invested: action.payload.total_money_invested ,
                current_value_of_tokens_purchased: action.payload.current_value_of_tokens_purchased,
                banner_data: action.payload.banner_data,
                total_tokens_purchased: action.payload.total_tokens_purchased,
                current_token_value: action.payload.current_token_value,
            };
        default:
            return state;
    }
};

export default dashboardReducer;
