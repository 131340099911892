import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { getDocumentData } from "../../../service/api";
import ButtonStyle from "../../Common/Button.module.css";
import URLS from "../../../utils/api-end-points";
import Styles from "./ViewModalComponent.module.css";
import DownloadPdf from "../../../Assets/Images/download-pdf.svg";
import DownloadArrow from "../../../Assets/Images/download-arrow.svg";
interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  mineId: number;
}
const ViewModalComponent = ({ showModal, setShowModal, mineId }: Props) => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const data = {
      mine_id: mineId,
    };
    const res: any = await getDocumentData(data);
    if (res.status) {
      setData(res.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleOpen = (link: string) => {
    window.open(URLS.BASE_URL + link.split("v1/")[1], "_blank");
  };
  return (
    <>
      {data.length > 0 && (
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body className={Styles.modalBody}>
            <div className={Styles.header1}>Public Documents</div>

            {data.map((info: any, index: number) => (
              <Row key={index} className={Styles.mobileRow}>
                <Col md={2} className="w-auto m-auto">
                  <img src={DownloadPdf} alt="PDF" />
                </Col>
                <Col className="m-auto">{info.doc_type_name}</Col>
                <Col md={2} className="w-auto">
                  <button
                    onClick={() => handleOpen(info.document_download_url)}
                    className={Styles.downloadArrow}>
                    <img src={DownloadArrow} alt="PDF" />
                  </button>
                </Col>
              </Row>
            ))}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ViewModalComponent;
