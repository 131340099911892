import React, { useEffect, useState } from "react";
import Layout from "../Layouts/Layout";
import FaqComponent from "../Components/FAQComponent";
import { getFAQData } from "../service/api";
const FAQ = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const res = await getFAQData();
    if (res.status) {
      setData(res.faqs);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Layout title="FAQ Page">
        <FaqComponent data={data} short={false} />
      </Layout>
    </>
  );
};

export default FAQ;
