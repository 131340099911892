import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./custom-alert.css";
import CloseIcon from "../../Assets/Images/cross-icon.svg";
import GreenTick from "../../Assets/Images/green-tick.svg";
import RedTick from "../../Assets/Images/red-tick.svg";
import { Row, Col } from "react-bootstrap";
import { setCloseAlertData } from "../../redux/actions/alert-action";
const BootstrapToast = () => {
  const { alertType, alertTitle, alertMessage, showAlert } = useSelector(
    (state: any) => state.alertReducer
  );
  const dispatch = useDispatch();
  const alertMapping = {
    SUCCESS: "success-alert",
    ERROR: "error-alert",
  };
  const closeAlert = () => {
    dispatch(setCloseAlertData());
  };
  return (
    <>
      {showAlert && (
        <div
          className={`alerts ${alertMapping[alertType]} position-fixed py-0 px-3 top-5 end-0 w-30`}
          style={{ zIndex: "1070" }}>
          <div
            id="liveToast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
            className="w-100 ">
            <Row>
              <Col md={2} className="m-auto">
                {alertType == "SUCCESS" ? (
                  <img src={GreenTick} alt="green-tick" />
                ) : (
                  <img src={RedTick} alt="red-tick" />
                )}
              </Col>
              <Col>
                <div className="toast-body">
                  <div className="text-end pr-3 pt-1">
                    <img
                      src={CloseIcon}
                      alt="close-icon"
                      className="cursor-pointer"
                      onClick={closeAlert}
                    />
                  </div>
                  {alertTitle && (
                    <h6 className="font-16 font-w-400">{alertTitle}</h6>
                  )}
                  {alertMessage && (
                    <p className="font-909090 font-14 font-w-400">
                      {alertMessage}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default BootstrapToast;
