import { FORGOT_PASSWORD } from "../redux-constant-type";

const initialState = {
  otp: "",
  otpType: false,
};

const forgotPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD.SET_OTP:
      return {
        ...state,
        otp: action.payload.otp,
      };

    case FORGOT_PASSWORD.SET_OTP_TYPE:
      return {
        ...state,
        otpType: action.payload.otpType,
      };

    default:
      return state;
  }
};

export default forgotPasswordReducer;
