
/* eslint-disable default-param-last */

import { MINE_TYPES } from "../redux-constant-type";

export interface MineType {
    mine_id: string;
    mine_name: string;
    own_qty: number;
    treasury_qty: number;
    mine_expected_end_date: Date;
    mine_expected_budget: number;
    mine_images:
    {
        image_id: string,
        mine_image_url: string
    }[]
}

export interface MineResellType {
    nft_id: string,
    name: string,
    mine_asset_id: string,
    available_qty: number,
    type: string,
    secondary_token_type: string,
    sale_price: number,
    mine_expected_end_date: Date,
    mine_expected_budget: number,
    mine_images:
    {
        image_id: string,
        mine_image_url: string
    }[]
}
interface state {
    mineList: MineType[],
    mineResellList: MineResellType[]
}
const initialState: state = {
    mineList: [],
    mineResellList: []
}

const mineReducer = (state = initialState, action:any) => {

    switch (action.type) {
        case MINE_TYPES.SET_MINE_DATA:
            return {
                ...state,
                mineList: [...action.payload.mineList],
            };
        case MINE_TYPES.SET_MINE_RESELL_DATA:
            return {
                ...state,
                mineResellList: [...action.payload.mineResellList],
            };
        default:
            return state;
    }
};

export default mineReducer;
