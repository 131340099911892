import React, { useState } from "react";
import { Row } from "react-bootstrap";
import Styles from "./SupportContactUs.module.css";
import GreyDot from "../../../Assets/Images/grey-dot.svg";
import { contactUsAPI } from "../../../service/api";
import notify from "../../../utils/notify";

const SupportContactUs = () => {
  const [msgcount, setMsgCount] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const handleMessage = (data: string) => {
    setMsg(data);
    setMsgCount(data.length);
  };

  const handleSubmit = async () => {
    const data = {
      name,
      email,
      message: msg,
      type: "get_in_touch",
    };

    try {
      const response: any = await contactUsAPI(data);
      if (response.status) {
        setName("");
        setEmail("");
        setMsg("");
        notify.success("We will get back to you soon.");
      } else {
        // handle here failure case
        notify.warning("Error Occured. Please try later.");
      }
    } catch (error) {
      console.error("An error occurred during getin touch:", error);
      notify.warning("Error Occured. Please try later.");
    }
  };
  return (
    <>
      <Row>
        <div className={Styles.card}>
          <div className={Styles.header}>
            <img src={GreyDot} alt="grey dot" className="me-2" />
            Contact us
          </div>
          <div className="mt-5">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}>
              <input
                type="text"
                required
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={Styles.fileds + " form-control col-12"}
              />
              <input
                type="email"
                required
                placeholder="Email"
                className={Styles.fileds + " form-control mt-3 col-12"}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <textarea
                placeholder="Message"
                value={msg}
                onChange={(e) => {
                  handleMessage(e.target.value);
                }}
                maxLength={150}
                required
                rows={4}
                className={Styles.messageBox + " mt-3 form-control col-12"}
              />
              <div className="col-12 mt-1 text-end">
                <span className={Styles.counter}>
                  {msgcount}/150 Characters
                </span>
              </div>
              <button type="submit" className={Styles.submitBtn + " mt-2"}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </Row>
    </>
  );
};
export default SupportContactUs;
