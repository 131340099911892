import { SECONDARY_SALE_TYPES } from "../redux-constant-type";

const setOrderBook = (values: any) => {
  return {
    type: SECONDARY_SALE_TYPES.SET_ORDER_BOOK,
    payload: {
      orderBook: values,
    },
  };
};

const setSecondaryTradeHistory = (values: any) => {
    return {
      type: SECONDARY_SALE_TYPES.SET_TRADE_HISTORY,
      payload: {
        tradeHistory: values,
      },
    };
  };

export {
    setOrderBook,setSecondaryTradeHistory
};
