import { LAYOUT_TYPES } from "../redux-constant-type";

const initialState = {
  screenSize: 0,
  loader: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAYOUT_TYPES.SET_LAYOUT:
      return {
        ...state,
        screenSize: action.payload,
      };
    case LAYOUT_TYPES.SET_LOADER:
      return {
        ...state,
        loader: action.payload,
      };

    default:
      return state;
  }
};

export default layoutReducer;
