
import { ORDER_TYPES } from "../redux-constant-type";

const setOrderList = (values: any) => {
  return {
    type: ORDER_TYPES.SET_ORDER_LIST,
    payload: {
      orderList: values,
    },
  };
};

export {
    setOrderList
};
