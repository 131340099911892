
/* eslint-disable default-param-last */

import { ORDER_TYPES } from "../redux-constant-type";

interface orderType {
    order_id: number,
    order_name: string,
    mine_id: any,
    mine_name: any,
    currency_id: number,
    currency_name: string,
    transaction_referenc: any,
    partner_name: string,
    qty: number,
    amount: number,
    date_order: Date,
    state: string,
    payment_mode: string,
    blockchain_url:string,
    order_type: string,
}
interface state {
    orderList: orderType[]
}
const initialState:state = {
    orderList: [],
};

const orderReducer = (state = initialState, action) => {
    
    switch (action.type) {
        case ORDER_TYPES.SET_ORDER_LIST:            
            return {
                ...state,
                orderList: [...action.payload.orderList],
            };
        default:
            return state;
    }
};

export default orderReducer;
