import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import SecondarySaleBody from "../Components/SecondarySale";

const SecondarySale = () => {
  return (
    <body style={{ backgroundColor: "#1d3118" }}>
      <UserLayout title="Secondary Sale" showPaddding={false}>
        <SecondarySaleBody />
      </UserLayout>
    </body>
  );
};

export default SecondarySale;
