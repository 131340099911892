import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import PurchaseComponent from "../Components/PurchaseComponent";
const PurchaseToken = () => {
  return (
    <UserLayout title="Purchase Token">
      <PurchaseComponent page="token" />
    </UserLayout>
  );
};
export default PurchaseToken;
