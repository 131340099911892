import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import PurchaseComponent from "../Components/PurchaseComponent";
const Purchase = () => {
  return (
    <UserLayout title="Purchase">
      <PurchaseComponent page="ico" />
    </UserLayout>
  );
};
export default Purchase;
