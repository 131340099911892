import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import ResellCollectionBody from '../Components/ResellCollection';

const ResellRequest = () => {
  return (
    <body style={{ backgroundColor: "#1d3118" }}>
      <UserLayout title="My Collection" showPaddding={false}>
        <ResellCollectionBody />
      </UserLayout>
    </body>
  );
};

export default ResellRequest;
