
/* eslint-disable default-param-last */

import { SECONDARY_SALE_TYPES } from "../redux-constant-type";

export interface OrderBookType {
    price: number,
    volume: number
}

export interface TradeHistoryType {
    price: number,
    volume: number,
    date: Date | string,
    trend: "down" | "up" | ""
}
interface state {
    orderBook: OrderBookType[],
    tradeHistory: TradeHistoryType[]
}
const initialState: state = {
    orderBook: [{
        price: 5.0,
        volume: 0
    },
    {
        price: 6.0,
        volume: 10
    }],
    tradeHistory: []
};

const secondarySaleReducer = (state = initialState, action) => {
    switch (action.type) {
        case SECONDARY_SALE_TYPES.SET_ORDER_BOOK:
            return {
                ...state,
                orderBook: [...action.payload.orderBook],
            };
        case SECONDARY_SALE_TYPES.SET_TRADE_HISTORY:
            return {
                ...state,
                tradeHistory: [...action.payload.tradeHistory],
            };
        default:
            return state;
    }
};

export default secondarySaleReducer;
