import { ALERT_TYPES } from "../redux-constant-type";

const initialState = {
  alertType: "",
  alertTitle: "",
  alertMessage: "",
  showAlert: false,
};

// eslint-disable-next-line default-param-last
const alertReducer = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_TYPES.SET_ALERT_DATA:
      return {
        ...state,
        alertType: action.payload.alertType,
        alertTitle: action.payload.alertTitle,
        alertMessage: action.payload.alertMessage,
        showAlert: action.payload.status,
      };
    default:
      return state;
  }
};

export default alertReducer;
