import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import MyCollectionBody from "../Components/MyCollection";

const MyCollection = () => {
  return (
    <body style={{ backgroundColor: "#1d3118" }}>
      <UserLayout title="My Collection" showPaddding={false}>
        <MyCollectionBody />
      </UserLayout>
    </body>
  );
};

export default MyCollection;
