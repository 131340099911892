import React from "react";
import DashBoardBody from "../Components/Dashboard/DashboardBody";
import UserLayout from "../Layouts/UserLayout/UserLayout";

const Dashboard = () => {
  return (
    <UserLayout showPaddding={false} title="Dashboard">
      <DashBoardBody />
    </UserLayout>
  );
};
export default Dashboard;
