import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import { ImCross } from "react-icons/im";
import commonStyles from './Button.module.css';


export interface ModalProps {
    onHide: () => void;
    onConfirm?: () => void;
    show: boolean;
    title?: string;
    titleClassName?: string;
    titleIcon?: string | JSX.Element;
    size?: "sm" | "lg" | "xl";
    bodyClassName?: string;
    children?: React.ReactNode;
    visibleHeaderCloseIcon: boolean;
    visibleFooterCloseButton: boolean;
    backdrop? : "static";
    confirmBtnText?: string;
    visibleFooter?: boolean;
    visibleConfirmBtn?: boolean;
    confirmBtnClassName?: string;
    disableConfirmBtn?: boolean;
  }

export const Modal: React.FC<ModalProps> = ({
    onHide,
    onConfirm,
    show,
    title,
    titleClassName = "text-center",
    titleIcon,
    size,
    bodyClassName,
    children,
    visibleFooterCloseButton,
    backdrop = "static",
    confirmBtnText = "ok",
    visibleFooter = true ,
    visibleConfirmBtn,
    confirmBtnClassName,
    disableConfirmBtn = false,
    visibleHeaderCloseIcon
  }) => {
    return (
      <BootstrapModal
      onHide={onHide}
      show={show}
      size={size}
      aria-labelledby=""
      centered
			backdrop={backdrop}
    >
      <BootstrapModal.Body className={bodyClassName}>
        {visibleHeaderCloseIcon ? (
          <div
            className={`d-flex align-items-center justify-content-between ${titleClassName}`}
          >
            <h5 className="mb-0">{title}</h5>
            <ImCross className="text-muted cursor-clickable rounded" onClick={onHide} />
          </div>
        ) : (
          <div className={`${titleClassName}`}>
            <h5 className="mb-0">
              {typeof titleIcon === "object" && titleIcon}
              {typeof titleIcon === "string" && (
                <img src={titleIcon} alt="title-icon" className="" />
              )}
              {title}
            </h5>
          </div>
        )}
        {children}
      </BootstrapModal.Body>
      { visibleFooter && <BootstrapModal.Footer className={visibleHeaderCloseIcon? '' :'justify-content-center'}>
          {visibleFooterCloseButton &&<button type="button" className="btn-secondary" onClick={onHide}>
            Close
          </button>
          }
          { visibleConfirmBtn &&<button disabled={disableConfirmBtn} className={` ${commonStyles.PlainBtn} ${commonStyles.ThemeOrangeBackground} ${confirmBtnClassName}`} onClick={onConfirm} >{confirmBtnText}</button> }
        </BootstrapModal.Footer>
      }
    </BootstrapModal>
    );
  };

  
