
import { DASHBOARD_TYPES } from "../redux-constant-type";

const setDashboardData = (values: any) => {
  return {
    type: DASHBOARD_TYPES.SET_DASHBOARD_DATA,
    payload: {
        orders_transactions: values.orders_transactions,
        total_money_invested: values.total_money_invested ,
        current_value_of_tokens_purchased: values.current_value_of_tokens_purchased,
        banner_data: values.banner_data,
        total_tokens_purchased: values.total_tokens_purchased,
        current_token_value: values.current_token_value
    },
  };
};

export {
    setDashboardData
};
