import { LAYOUT_TYPES } from "../redux-constant-type";

const setScreenSize = (num: number) => {
  return {
    type: LAYOUT_TYPES.SET_LAYOUT,
    payload: num,
  };
};
const setLoader = (bol: boolean) => {
  return {
    type: LAYOUT_TYPES.SET_LOADER,
    payload: bol,
  };
};
export { setScreenSize, setLoader };
